<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">
        我的实验
        <div class="list_operate_btns" v-if="zone_user">
          <el-button type="primary" size="small" @click="diy_experiment()">进入开放实验室</el-button>
        </div>
      </h4>
      <template v-if="exptList.length > 0">
        <ul class="expt_list_common pointer">
        <li v-for="item in exptList" :key="item.id" >
          <div class="cover" @click="goPath(item)">
            <img :src="item.cover_image" class="image" v-if="item.cover_image"/>
            <img :src="require('assets/img/course/cover.png')" v-else/>
            <span class="tag" v-if="item.type">
                <span v-if="item.type == 'curriculum' ">课程实验</span>
                <span v-if="item.type == 'diy' ">开放实验</span>
            </span>
          </div>
          <div class="content">
            <h4  @click="goPathInfo(item)">{{ item.name }}</h4>
            <div class="info" v-if="item.type != 'diy' ">所属课程:<a href="javascript:;" @click="goPath(item)">{{item.belong}}</a></div>
            <div class="info">运行状态:{{item.state}}</div>
            <div class="btns">
              <el-button type="primary" size="small" v-if="item.type != 'diy' " @click="continue_expt(item)">继续实验</el-button>
              <el-button type="primary" size="small" v-else @click="continue_diy_expt(item.uuid)">继续实验</el-button>
              <el-button type="danger" size="small" v-if="item.type != 'diy' " @click="delete_expt(item)">结束实验</el-button>
              <el-button type="danger" size="small" v-else @click="close_diy_expt(item)">结束实验</el-button>
            </div>
          </div>
        </li>
      </ul>
        <!-- 分页 -->
        <Pagination :pager="pager" @getPager="getExptPager"></Pagination>
      </template>
      <el-empty description="暂无实验" v-else></el-empty>
    </div>
  </div>
</template>

<script>
import Pagination from "components/common/Pagination"
import { getExptList, deleteExpt, checkZoneUser, closeDiyExpt,continueExptRecord } from 'api/experiment/list'
import { showConfirmMessageBox, showMessage } from 'api/base/message'
export default {
  components: {
    Pagination
  },
  data() {
    return  {
      zone_user:false,
      exptList:[],
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
    }
  },
  mounted() {
    this.getData()
    this.checkzoneuser()
  },
  methods: {
    getExptPager(pager) {
      this.pager = pager
      this.getData()
    },
    getData() {
      const that = this
      var data = {}
      data['offset'] = (that.pager.currentPage - 1) * (that.pager.pageSize)
      data['limit'] = that.pager.pageSize
      getExptList(data).then((res) => {
        this.exptList = res.data
        this.pager.total = res.total

      })
    },
    goPath(item) {
      if (item['type'] == 'diy'){
        this.$router.push(`/diy/detail/${item.uuid}/?from=${window.location.pathname}`);
      }else{
        this.$router.push('/course/detail/'  + item.curr_uuid)
      }
    },
    goPathInfo(item){
       if (item['type'] == 'diy'){
        this.continue_diy_expt(item.uuid);
      }else{
        this.continue_expt(item)
      }
    },
    delete_expt(item){
      showConfirmMessageBox('确定结束实验吗?').then(() =>{
        var data = {'uuid':item.uuid,'name':item.name}
        deleteExpt(data).then((res) => {
          if(res.data.code == 0){
            this.getData()
          }else{
            this.$alert(`${res.data.err_message}`, '提示', {
              confirmButtonText: '确定',
            });
          }
        })
      });
    },
    close_diy_expt(item){
      showConfirmMessageBox('确定结束实验吗?').then(() => {
        closeDiyExpt({'uuid':item.uuid,'name':item.name}).then((res) => {
          if(res.data.code == 0){
            this.getData()
          }else{
            this.$alert(`${res.data.err_message}`, '提示', {
              confirmButtonText: '确定',
            });
          }
        })
      });

    },
    continue_expt(item){
      var record_data = {'curr_uuid':item.curr_uuid,'expt_uuid':item.course_uuid,'instance_uuid':item.uuid}
      continueExptRecord(record_data).then(() => {
        this.$router.push(`/expt/detail/${item.uuid}/?cur_uuid=${item.curr_uuid}&from=${window.location.pathname}`);
      })
    },
    continue_diy_expt(uuid){
      this.$router.push(`/diy/detail/${uuid}/?from=${window.location.pathname}`);
    },
    checkzoneuser(){
      checkZoneUser({'check_zone_user':true}).then((res) => {
        if (res.code == 1){
//              展示
          this.zone_user = true
        }else{
          this.zone_user = false
        }
      })
    },
    diy_experiment(){
      this.$router.push(`/diy/experiment`);
    }
  }
}
</script>

<style lang="scss" scoped>
.expt_list_common {
  .content {
    .info {
      > a {
        color:#666;
        &:hover {
          color:#00adef;
        }
      }
    }
  }
}
</style>
